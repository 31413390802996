import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import ContentImage from '../../../../components/ContentImage';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import Breadcrumb from '../../../../components/Breadcrumb';
import {lang} from '../../../../helpers/lang';

const Environment = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			background: file(relativePath: {eq: "pages/work/sectors/drought.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			protectedAreas: file(
				relativePath: {eq: "pages/work/sectors/environment/protected_areas.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			landAccounting: file(
				relativePath: {eq: "pages/work/sectors/environment/land_accounting.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			thermalComfort: file(
				relativePath: {eq: "pages/work/sectors/environment/thermal_comfort.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout theme={pageContext.sector.theme}>
			<SEO title={pageContext.sector.title} lang={lang} />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>
					Environmental monitoring and natural accounting
				</HighlightedTitle>
				<HighlightedParagraph>
					Environmental monitoring provides critical support for our challenging
					commitments targeting the resilience of ecosystems and society.
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#protected-areas-monitoring'}>
								Protected Areas Monitoring
							</a>
							<a href={'#land-and-ecosystem-accounting'}>
								Land and Ecosystem Accounting
							</a>
							<a href={'#land-use-carbon-storage'}>
								Increasing Land Use Carbon Storage Capacity
							</a>
							<a href={'#fragmentation'}>Fragmentation</a>
							<a href={'#urban-sprawl'}>Urban Sprawl</a>
							<a href={'#thermal-comfort'}>Thermal comfort</a>
							<a href={'#support-sustainable-development'}>
								Support to sustainable development
							</a>
						</Anchors>

						<PersonBadge
							note={pageContext.sector.contactNote}
							email={pageContext.sector.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						Environmental changes or disturbances are caused by human influences
						and natural/or ecological processes. Humans impact the physical
						environment in many ways: overpopulation, pollution, burning fossil
						fuels or deforestation. Changes like these have triggered climate
						change, soil erosion, biodiversity decline, poor air quality and
						undrinkable water.
					</p>
					<p>
						Earth Observation data are a strong source of information for
						environmental monitoring and natural accounting that is essential
						for ecosystem monitoring, habitat mapping and biodiversity
						assessments. Repeated satellite measurements allow for continuous
						monitoring of ecosystems and are used to detect changes in
						distribution and to quantify the magnitude. Advances in spatial and
						temporal resolution of available satellite imagery mean that
						analysis can now be supported from global to local scales. Our
						solutions support many environmental applications, including the
						most challenging ones such targeting climate change reduction and
						mitigation and halting biodiversity loss, providing evidence for
						informed actions, improving their efficiency and targeting their
						impact.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'protected-areas-monitoring'}>Protected Areas Monitoring</h2>
					<p>
						Designated nature areas (at national or the European level)
						represent a valuable network of precious localities with specific
						management and dedicated protection. To maintain and improve these
						networks it is necessary to monitor compliance with these rules and
						support conservation status observation for the habitats and species
						present in each of the sites. Our solutions offer the effective
						means of such monitoring, support the evidence-based management,
						detection of potential weak implementation or infringement. They
						also help to identify potential problems early enough and to
						initiate discussion about the effectiveness of existing policies or
						strategies.
					</p>
					<ContentImage
						fluid={
							data
								? data.protectedAreas.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>
								Example: EU Grassland Watch developed for European Narura 2000
								sites monitoring and analysis based on CLMS Corine Land Cover
								data. © GISAT, s4e, Bilbomatica and Spectro Natura
							</>
						}
					/>
					{/*<ContentImage*/}
					{/*	fluid={null}*/}
					{/*	legend={`Obr: Etiopie Water Management (distribuce populace a dostupnost vody) pro PIN*/}
					{/*Slumy (Karachi, Dhaka) pro WB*/}
					{/*Subsidence (Vinh Long) pro WB*/}
					{/*Agriculture / Water management Cambodia pro ADB nebo Urban & Flood management v Myanmaru pro ADB`}*/}
					{/*/>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'land-and-ecosystem-accounting'}>
						Land and Ecosystem Accounting
					</h2>
					<p>
						Land and Ecosystem Accounting, as a part of natural capital
						accounting, provides harmonised information on the total stocks and
						flows of natural resources and services in a given ecosystem or
						region. Based on sound international methodology, our solutions
						provide long-term and up-to-date data on changes in land cover and
						land use and trace the wider environmental, social and economic
						implications of these transformations and their relation to human
						uses and functions in the landscape. Such information can
						subsequently support government, corporate and consumer decision
						making as each relates to the use or consumption of natural
						resources and can be used to prove the sustainable behaviour.
					</p>
					<ContentImage
						fluid={
							data
								? data.landAccounting.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>
								Example: Land Accounts prepared for European Environmental
								Agency based on CLMS Corine Land Cover data. ©GISAT and EEA
							</>
						}
					/>
					{/*<ContentImage*/}
					{/*	fluid={null}*/}
					{/*	legend={*/}
					{/*		<p style={{color: 'red'}}>*/}
					{/*			Example (Obr): slumy, ideálně vč. denzity (dopočítat) , IDP*/}
					{/*			ethiopia pro UNHCR, GIO-Kathmandu*/}
					{/*		</p>*/}
					{/*	}*/}
					{/*/>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'land-use-carbon-storage'}>
						Increasing Land Use Carbon Storage Capacity
					</h2>
					<p>
						Contribution to CO2 in the atmosphere can be reduced by taking
						advantage of the fact that atmospheric CO2 can accumulate as carbon
						in vegetation and soils in terrestrial ecosystems. Human activities
						affect changes between the carbon pools and the role of Land Use,
						Land-Use Change and Forestry (LULUCF) activities in the mitigation
						of climate change has long been recognized. It identifies
						agriculture, forestry and other land uses as a significant net
						source of greenhouse gas (GHG) emissions. The mitigation can be
						achieved through planned activities in the LULUCF sector promoting
						the land use and land cover changes that increase the removals of
						GHGs from the atmosphere or decrease emissions by halting the loss
						of carbon stocks. Our solutions provide up-to-date information and
						guidance for such activities from a local to country perspective.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'fragmentation'}>Fragmentation</h2>
					<p>
						Landscape fragmentation is the physical disintegration of continuous
						land, which is most often caused by urban or transport network
						expansion. Breaking structural connections between habitats results
						in decreased resilience of habitats and a decrease in their ability
						to provide various ecosystem services and support biodiversity.
						Thus, fragmentation is mentioned in the context of the European
						Green Deal as one of the main drivers of environmental challenges in
						the EU with respect to biodiversity loss (BDS2030). Besides
						biodiversity consequences, fragmentation of natural and agricultural
						land often leads to a change in the actual land use and thus might
						impact the carbon-sequestration potential in a particular area.
					</p>
					<p>
						A large variety of fragmentation measures exists, but many of them
						do not address individual phases of the landscape fragmentation
						process in a consistent way. Based on Earth Observation data we
						provide operational standard indicators for fragmentation by urban
						and transport infrastructure expansion based on the effective mesh
						size and effective mesh density methodology developed at the ETH
						Zurich (Jaeger 2000, 2002, 2004).
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'urban-sprawl'}>Urban Sprawl</h2>
					<p>
						Urban sprawl affects the essential environmental, economic and
						social functions performed by soils and landscapes. It alters the
						connection with natural cycles and services, including those
						important for climate change mitigation and adaptation. It has also
						an important effect on urban planning, economic decisions, land
						market, mobility, accessibility and changes in lifestyle in general.
						As such, urban sprawl can be viewed as a typical example of a
						phenomenon with a cumulative effect, related also to landscape
						fragmentation. Similarly, urban sprawl changes the actual land use
						and thus the carbon sequestration potential in a particular area.
						Moreover, it also leads to an increase of transportation and other
						services costs which is an important aspect of the wider
						de-carbonization discussion.
					</p>
					<p>
						We provide operational standard indicators on ‘Urban sprawl in
						Europe’ based on the Weighted Urban Proliferation (WUP) methodology
						using a composite measure of the degree of urban sprawl developed at
						Concordia University (Jaeger, 2010, Jaeger and Schwick, 2014). The
						methodology focused on three dimensions of urban sprawl: the
						expansion of urban areas, the dispersion of settlement areas and the
						density of new development (per capita/per job utilization).
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'thermal-comfort'}>Thermal comfort</h2>
					<p>
						Heat stress is an increasing problem in many cities around the globe
						due to the ongoing climate change. Citizens experience higher levels
						of heat stress than people living in rural areas due to dominant
						sealed surfaces and air temperatures, lower wind speeds and higher
						levels of solar and thermal radiation coming from building
						materials. Heat stress has a negative impact on living quality:
						sleep, productivity, morbidity and mortality of urban residents. It
						is therefore essential to have a good understanding of the factors
						contributing to heat stress, identifying hot spots in a city, and
						assessing the effectiveness of adaptation measures (e.g. green
						infrastructure quality addressed in Nature Based Solutions).
					</p>
					<ContentImage
						fluid={
							data
								? data.thermalComfort.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>Example: Thermal comfort (WBGT) analysis. ©GISAT and VITO</>
						}
					/>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'support-sustainable-development'}>
						Support to sustainable development
					</h2>
					<p>
						Humanitarian, development and reconstruction aid stakeholders are
						facing a lack of reliable and up-to-date information ready to
						support evidence-based decision making essential for distribution
						and targeting development and livelihood improvement activities.
						Satellite imagery in conjunction with open data such as Open Street
						Maps, World Settlement Footprint, Geonames and other local and
						global resources provide a baseline for application of disruptive
						technologies to generate actionable geo-intelligence applicable in
						data scarce environments.
					</p>
					<p>
						Gisat has a proven track record of provision of geospatial services
						in support of humanitarian aid and development agencies,
						multilateral development banks and technical assistance projects in
						low- and middle-income countries. Over the past decade we have
						supported several international agencies, banks or national
						institutions either directly or as a qualified service provider in
						the scope of European Copernicus Emergency Management Service
						(People in Need, DG-ECHO, WFP, UNHCR, WBG, ADB, EIB, IADB etc.).
					</p>
					<h3>Population and IDF/Refugee mapping</h3>
					<p>
						Population is mapped from satellite data and other open sources to
						detect location, extent and evolution of various settlements
						(villages, hamlets, individual buildings and huts) at local to
						regional level. Characteristics of slum or refugee/IDP camps such as
						dwelling density and typology are extracted to provide qualified
						estimates of population distribution, density and its evolution, as
						well as vulnerability, deprivation levels, and to scale supporting
						development and rehabilitation actions.
					</p>
					<h3>Trafficability & accessibility</h3>
					<p>
						Accessibility of water (or other) resources is accomplished by
						mapping population distribution and analysis of transportation
						networks from open data, usually complemented by interpretation of
						satellite imagery. Settlements are categorized by typology and
						distance (accessibility) bands to water resources to reveal patterns
						of deprivation and to provide input into modelling of affected
						populations in response to resources’ built-up or malfunction
						scenarios. Transport network assessment can be facilitated by
						analysis of seasonal patterns related to weather, moisture or
						flooding probability that indicate varying trafficability throughout
						the season.
					</p>
					<h3>Impacts on agriculture and natural resources</h3>
					<p>
						Proxies of natural resources exploitation from pressure inflicted by
						population growth or large displacements, impacts of conflicts and
						insecurity can be measured from satellite imagery. We detect
						deforestation and natural vegetation withdrawal patterns, increase
						in soil erosion, oscillating intensity of cultivations, irrigation
						or fields abandonment by analysis of time series of satellite data,
						object-based and machine learning algorithms. Analysis is
						complemented by assessment of spatial and temporal association to
						explanatory variables such as chronology of events, seasonal
						conditions, topographic, positional and distance settings.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

Environment.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default Environment;
